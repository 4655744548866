<template>
    <div class="button_group" style="margin-top: 10px;">
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/bet365_premiership', query: {t: new Date().getTime()}}">프리미어</router-link>
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">슈퍼리그</router-link>
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/bet365_eurocup', query: {t: new Date().getTime()}}">유로컵</router-link>
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/bet365_worldcup', query: {t: new Date().getTime()}}">월드컵</router-link>
    </div>
</template>

<script>
    export default {
        name: "LeisureB365SoccerLinks"
    }
</script>

<style scoped>

</style>